import React, { useState } from "react"
import logo from "../images/logo.svg"
import { Link } from "gatsby"

const Nav = ({ clickScroll, fixedHeader, solidNav, page }) => {
  const [navOpen, setNavOpen] = useState(false)
  return (
    <div
      id="nav"
      className={`header ${solidNav ? "" : "transparent"} ${
        fixedHeader ? "fixed" : ""
      }`}
    >
      <div className="nav-bar">
        {page === "home" ? (
          <div
            className="logo"
            onClick={() => {
              setNavOpen(false)
              clickScroll("headerRef")
            }}
          >
            <img src={logo} alt={logo} />
            <span>Hermon Prayer Hall</span>
          </div>
        ) : (
          <Link className="logo" to="/">
            <img src={logo} alt={logo} />
            <span>Hermon Prayer Hall</span>
          </Link>
        )}

        <div className="menu">
          <div
            id="hamburger"
            onClick={() => setNavOpen(!navOpen)}
            className={`hamburglar ${navOpen ? "is-closed" : "is-open"}`}
          >
            <div id="top" />
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="64px"
              height="64px"
              viewBox="0 0 64 64"
              enable-background="new 0 0 64 64"
            >
              <path
                id="circle"
                fill="none"
                stroke-width="4"
                stroke-miterlimit="10"
                d="M16,32h32c0,0,11.723-0.306,10.75-11
                                                                             c-0.25-2.75-1.644-4.971-2.869-7.151C50.728,7.08,42.767,2.569,33.733,2.054C33.159,2.033,32.599,2,32,2C15.432,2,2,15.432,2,32
                                                                             c0,16.566,13.432,30,30,30c16.566,0,30-13.434,30-30C62,15.5,48.5,2,32,2S1.875,15.5,1.875,32"
              />
            </svg>
            <div id="bottom" />
          </div>
          <ul className={`menu-items ${navOpen ? "opened" : "closed"}`}>
            {page === "home" ? (
              <li
                onClick={() => {
                  setNavOpen(false)
                  clickScroll("joinusRef")
                }}
                className="slide"
              >
                <span>
                  <span className="act">I am New</span>
                  <span className="alt">Join Us</span>
                </span>
              </li>
            ) : (
              <li className="slide">
                <Link to="/#joinus">
                  <span className="act">I am New</span>
                  <span className="alt">Join Us</span>
                </Link>
              </li>
            )}

            {page === "home" ? (
              <li
                onClick={() => {
                  setNavOpen(false)
                  clickScroll("fellowshipRef")
                }}
              >
                <span>Fellowship</span>
              </li>
            ) : (
              <li>
                <Link to="/#fellowship">Fellowship</Link>
              </li>
            )}

            {page === "home" ? (
              <li
                onClick={() => {
                  setNavOpen(false)
                  clickScroll("contactRef")
                }}
              >
                <span>Contact</span>
              </li>
            ) : (
              <li>
                <Link to="/#contact">Contact</Link>
              </li>
            )}
            {page === "home" ? (
              <li
                onClick={() => {
                  setNavOpen(false)
                  clickScroll("aboutRef")
                }}
              >
                <span>About</span>
              </li>
            ) : (
              <li>
                <Link to="/#about">About</Link>
              </li>
            )}
            {page === "home" ? (
              <li
                onClick={() => {
                  setNavOpen(false)
                  clickScroll("followRef")
                }}
              >
                <span>Follow</span>
              </li>
            ) : (
              <li>
                <Link to="/#follow">Follow</Link>
              </li>
            )}

            {/* <li>
              <Link to="/songs">
                <span>Songs</span>
              </Link>
            </li> */}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Nav
