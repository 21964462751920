import React, { useState, useEffect } from "react"
import Nav from "./Nav"
import "../sass/main.scss"

const Layout = ({ page, solidNav, children, clickScroll }) => {
  const [fixedHeader, setFixedHeader] = useState(false)
  const [langLabel, setLangLabel] = useState("English")

  useEffect(() => {
    if (document.documentElement.scrollTop || document.body.scrollTop > 0) {
      setFixedHeader(true)
    } else {
      setFixedHeader(false)
    }
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  })

  const handleScroll = () => {
    if (document.documentElement.scrollTop || document.body.scrollTop > 0) {
      setFixedHeader(true)
    } else {
      setFixedHeader(false)
    }
  }

  return (
    <div className={page} id="hph_page">
      {/* <div className="languageSwith">
        <label
          onClick={() =>
            langLabel === "Telugu"
              ? setLangLabel("English")
              : setLangLabel("Telugu")
          }
        >
          {langLabel}
        </label>
      </div> */}
      <Nav
        page={page}
        solidNav={solidNav}
        fixedHeader={fixedHeader}
        clickScroll={refstr => clickScroll(refstr)}
        scrolled={fixedHeader}
      />
      <div id="page">{children}</div>
    </div>
  )
}

export default Layout
